var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var cloner_service_1 = require("./utilities/cloner.service");
// Will be used to create a singleton
var ObservableStoreBase = /** @class */ (function () {
    function ObservableStoreBase() {
        this._storeState = null;
        this._clonerService = new cloner_service_1.ClonerService();
        this._extensions = [];
        this.settingsDefaults = {
            trackStateHistory: false,
            logStateChanges: false,
            // deprecated
            includeStateChangesOnSubscribe: false,
            stateSliceSelector: null
        };
        this.stateHistory = [];
        this.globalStateDispatcher = new rxjs_1.BehaviorSubject(null);
        this.globalStateWithChangesDispatcher = new rxjs_1.BehaviorSubject(null);
        this.globalSettings = null;
        this.services = []; // Track all services reading/writing to store. Useful for extensions like DevToolsExtension.
    }
    Object.defineProperty(ObservableStoreBase.prototype, "isStoreInitialized", {
        get: function () {
            return this._storeState !== null;
        },
        enumerable: false,
        configurable: true
    });
    ObservableStoreBase.prototype.initializeState = function (state) {
        if (this.isStoreInitialized) {
            throw Error('The store state has already been initialized. initializeStoreState() can ' +
                'only be called once BEFORE any store state has been set.');
        }
        return this.setStoreState(state);
    };
    ObservableStoreBase.prototype.getStoreState = function (propertyName, deepCloneReturnedState) {
        if (propertyName === void 0) { propertyName = null; }
        if (deepCloneReturnedState === void 0) { deepCloneReturnedState = true; }
        var state = null;
        if (this.isStoreInitialized) {
            // See if a specific property of the store should be returned via getStateProperty<T>()
            if (propertyName) {
                if (this._storeState.hasOwnProperty(propertyName)) {
                    state = this._storeState[propertyName];
                }
            }
            else {
                state = this._storeState;
            }
            if (state && deepCloneReturnedState) {
                state = this.deepClone(state);
            }
        }
        return state;
    };
    ObservableStoreBase.prototype.setStoreState = function (state, deepCloneState) {
        if (deepCloneState === void 0) { deepCloneState = true; }
        var currentStoreState = this.getStoreState(null, deepCloneState);
        if (deepCloneState) {
            this._storeState = __assign(__assign({}, currentStoreState), this.deepClone(state));
        }
        else {
            this._storeState = __assign(__assign({}, currentStoreState), state);
        }
    };
    ObservableStoreBase.prototype.clearStoreState = function () {
        this._storeState = null;
    };
    ObservableStoreBase.prototype.deepClone = function (obj) {
        return this._clonerService.deepClone(obj);
    };
    ObservableStoreBase.prototype.addExtension = function (extension) {
        this._extensions.push(extension);
        extension.init();
    };
    return ObservableStoreBase;
}());
// Created once to initialize singleton
exports.default = new ObservableStoreBase();
