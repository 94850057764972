import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './components/top-nav/top-nav.component';
import {RouterModule} from '@angular/router';
import {VeranstaltungDateComponent} from './components/veranstaltung-date/veranstaltung-date.component';
import {KontakteDataService} from './services/kontakte-data.service';
import {TeilnahmeStatusComponent} from './components/teilnahme-status/teilnahme-status.component';
import {RepraesentationenDataService} from './services/repraesentationen-data.service';
import {SortimenteDataService} from './services/sortimente-data.service';
import {MercatusToastyService} from './services/mercatus-toasty.service';
import {TruncatePipe} from './pipes/truncate.pipe';
import {QuantitySelectorComponent} from './components/quantity-selector/quantity-selector.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CanDeactivateGuard} from './guards/canDeactivateGuard';
import {AngebotsartenDataService} from './services/angebotsarten-data.service';
import {AngebotsartComponent} from './components/angebotsart/angebotsart.component';
import {ReportingDataService} from './services/reporting-data.service';
import {BedarfeEditComponent} from './components/bedarfe-edit/bedarfe-edit.component';
import {TableModule} from 'primeng/table';
import {PdfPreviewComponent} from './components/pdf-preview/pdf-preview.component';
import {ReportingHistoryComponent} from './components/reporting-history/reporting-history.component';
import {ProdukteAutocompleteComponent} from './components/produkte-autocomplete/produkte-autocomplete.component';
import {AngebotsartenDropdownComponent} from './components/angebotsarten-dropdown/angebotsarten-dropdown.component';
import {SerienIconComponent} from './components/serien-icon/serien-icon.component';
import {LaufmeterInformationComponent} from './components/laufmeter-information/laufmeter-information.component';
import {RepraesentationartDropdownComponent} from './components/repraesentationart-dropdown/repraesentationart-dropdown.component';
import {RepraesentationKommentarComponent} from './components/repraesentation-kommentar/repraesentation-kommentar.component';
import {DropdownModule} from 'primeng/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { RowsLocalstoragePersistenceDirective } from './directives/rows-localstorage-persistence.directive';
import { OldTeilnahmenComponent } from './components/old-teilnahmen/old-teilnahmen.component';
import {FilterLocalstoragePersistenceDirective} from './directives/filter-localstorage-persistence.directive';
import {MyMercatusService} from "./services/my-mercatus-service";
import {UploadListComponent} from "./components/upload-list/upload-list.component";
import {UploadFormComponent} from "./components/upload-list/upload-form/upload-form.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DropdownModule,
        TableModule,
        TooltipModule.forRoot(),
        AutoCompleteModule,
        ConfirmDialogModule,
        ReactiveFormsModule,
    ],
    declarations: [
        HeaderComponent,
        AngebotsartComponent,
        VeranstaltungDateComponent,
        TeilnahmeStatusComponent,
        TruncatePipe,
        QuantitySelectorComponent,
        BedarfeEditComponent,
        PdfPreviewComponent,
        ReportingHistoryComponent,
        ProdukteAutocompleteComponent,
        AngebotsartenDropdownComponent,
        SerienIconComponent,
        LaufmeterInformationComponent,
        RepraesentationartDropdownComponent,
        RepraesentationKommentarComponent,
        RowsLocalstoragePersistenceDirective,
        OldTeilnahmenComponent,
        FilterLocalstoragePersistenceDirective,
        UploadListComponent,
        UploadFormComponent
    ],
    exports: [
        HeaderComponent,
        VeranstaltungDateComponent,
        TeilnahmeStatusComponent,
        AngebotsartComponent,
        TruncatePipe,
        QuantitySelectorComponent,
        BedarfeEditComponent,
        PdfPreviewComponent,
        ReportingHistoryComponent,
        ProdukteAutocompleteComponent,
        AngebotsartenDropdownComponent,
        LaufmeterInformationComponent,
        RepraesentationartDropdownComponent,
        SerienIconComponent,
        RepraesentationKommentarComponent,
        RowsLocalstoragePersistenceDirective,
        OldTeilnahmenComponent,
        FilterLocalstoragePersistenceDirective,
        UploadListComponent
    ],
    providers: [
        ConfirmationService
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                KontakteDataService,
                AngebotsartenDataService,
                SortimenteDataService,
                RepraesentationenDataService,
                ReportingDataService,
                MyMercatusService,
                MercatusToastyService,
                QuantitySelectorComponent,
                AngebotsartComponent,
                CanDeactivateGuard,
                AngebotsartenDropdownComponent,
                RepraesentationartDropdownComponent,
                OldTeilnahmenComponent
            ]
        };
    }
}
